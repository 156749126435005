.subLayerFilter {
  padding: 0px 24px;
  width: 100%;
  box-shadow: 0px 24.047481536865234px 42.436729431152344px 1.414557695388794px
    rgba(163, 174, 187, 0.2);
  position: absolute;
  left: 0;
  background: white;
  z-index: 4;
  border-radius: 8px;
}

@media screen and (min-width: 768px) {
  .subLayerFilter {
    width: 340px;
  }

  .activeSubLayerFilter {
    top: 50px;
  }
}

@media screen and (min-width: 1280px) {
  .subLayerFilter {
    width: 426px;
  }

  .activeSubLayerFilter {
    top: 60px;
  }
}
